'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

function _interopDefault (ex) { return (ex && (typeof ex === 'object') && 'default' in ex) ? ex['default'] : ex; }

var _$1 = _interopDefault(require('lodash'));
var Cookies = _interopDefault(require('js-cookie'));

var fetch = require('node-fetch');

global.activeRequestsCount = 0;

var api = {
  async request({uri, body={}}, {token, shouldAlert=true, isPolling=false, files}={}) {
    if (!isPolling) global.activeRequestsCount += 1;

    var isReactNative = typeof(navigator) !== 'undefined' && navigator.product === 'ReactNative';
    var NODE_ENV = process.env.NODE_ENV;

    if (typeof(__DEV__) !== 'undefined' && !__DEV__ && NODE_ENV === 'development') {
      NODE_ENV = 'production';
    }

    var domain = {
      development: 'http://localhost:3301',
      production: 'https://symbolic-frameworks-api.herokuapp.com'
    }[NODE_ENV];

    if (global.API_DOMAIN || process.env.REACT_APP_API_DOMAIN || process.env.API_DOMAIN) {
      domain = global.API_DOMAIN || process.env.REACT_APP_API_DOMAIN || process.env.API_DOMAIN;
    }
    else if (process.env.REACT_APP_LOCAL_IP || process.env.LOCAL_IP) {
      domain = `http://${process.env.REACT_APP_LOCAL_IP || process.env.LOCAL_IP}:3301`;
    }

    var url = `${domain}${uri}`;
    var requestParams = {method: 'post', mode: 'cors'};

    body.token = body.token || token || global.sessionToken || Cookies.get('sessionToken');
    body.appVersion = global.APP_VERSION;
    body.appBuildNumber = global.APP_BUILD_NUMBER;
    body.isWeb = global.IS_WEB;
    body.appKey = global.APP_KEY;

    if (files) {
      requestParams.body = new FormData();

      _$1.forEach(files, (file, f) => requestParams.body.append(f, file));

      if (body.resources) requestParams.body.append('resources', JSON.stringify(body.resources));

      requestParams.body.append('token', body.token);
    }
    else {
      requestParams.body = JSON.stringify(body);
      requestParams.headers = {'Content-Type': 'application/json'};
    }

    try {
      var response = await fetch(url, requestParams);
      var jsonResponse = await response.json();
    }
    catch (e) {
      var response = {ok: false};
      var jsonResponse = {errors: [{message: e.message}]};
    }

    if (!isPolling) global.activeRequestsCount -= 1;

    if (!response.ok) {
      var message = _$1.join(_$1.map(jsonResponse.errors, 'message'), ', ');

      if (message === 'Failed to fetch') message = 'There was an issue connecting to our server. Please check your connection and try again shortly.';

      if ((shouldAlert && !isReactNative) || _$1.includes(message, 'out of date')) alert(message);

      console.log(jsonResponse);

      throw new Error(message);
    }

    return jsonResponse;
  },

  async resources(resources, options) {
    var response = await api.request({uri: '/resources', body: {resources}}, options);

    return response;
  },

  async act(actionKey, resourceKey, params={}, options) {
    var response = await api.resources({[actionKey]: {[resourceKey]: params}}, options);

    return _$1.get(response, `data.resources.${actionKey}.${resourceKey}`);
  },

  get(...args) {
    return api.act('get', ...args);
  },

  create(resourceKey, propsData={}, ...args) {
    return api.act('create', resourceKey, Array.isArray(propsData) ? _$1.map(propsData, props => ({props})) : {props: propsData}, ...args);
  },

  update(...args) {
    return api.act('update', ...args);
  },

  destroy(...args) {
    return api.act('destroy', ...args);
  }
};

var date = {};

date.formatDate = (momentInstance) => {
  if (!momentInstance) return '';

  var formatString = momentInstance.year() !== new Date().getFullYear() ? 'M/D/YY' : 'MMM D';

  return momentInstance.format(formatString);
};

date.formatDatetime = (momentInstance, {short=false}={}) => {
  var now = new Date();
  var format = short ? 'M/D/YY' : 'M/D/YY h:mma'; //standard long form - m/d/y 00:00am
  var timeDifference = _$1.round(Math.abs(momentInstance.diff(now) / 1000), 0);
  var secondsInOneDay = 60 * 60 * 24;

  if (timeDifference < secondsInOneDay) {
    format = 'h:mma'; // today - 00:00am
  }
  else if (now.getFullYear() === momentInstance.year()) {
    format = short ? 'M/D' : 'M/D h:mma'; // this year - m/d 00:00am
  }

  return momentInstance.format(format);
};

var colors = {};

colors.all = [
  //browns
  '#F4BB86',
  '#F7DE8F',
  '#EDD9C4',

  //reds
  '#FFAAAA',
  '#F6C0C0',
  '#DDBEBE',

  //purples
  '#A8A9F0',
  '#CDB4EB',
  '#E4C6E2',

  //blues
  '#9DBBEF',
  '#B7D2FF',
  '#BBD9E5',

  //greens
  '#C6E7E2',
  '#BEE2BE',
  '#D0D48C',
];

colors.fun = [
  '#ABC0DB',
  '#FACD55',
  '#C8D4A3',
  '#A9E1D3',
  '#FB9FC3',
  '#F89A90',
  '#B8B3FF',
];

colors.colorFor = (arg) => {
  var hue;

  if (typeof(arg) === 'object') {
    var {status, user, org} = arg;

    if (user) {
      hue = user.hue;
      arg = user.id - 1;
    }
    else if (org) {
      hue = org.hue;
      arg = org.id - 1;
    }
    else {
      var id = arg.userId || arg.orgId || arg.id || 1;

      arg = id - 1;
    }
  }

  var color = '';

  if (status) {
    if (status === 'archived') color = '#CCCCCC';
    else if (status === 'pending') color = '#F7DE8F';
    else if (status === 'planning') color = '#CCBEDD';
    else if (status === 'ready') color = '#D0D48C';
    else if (status === 'active') color = '#BAC4E3';
    else if (status === 'complete') color = '#AFD298';
  }
  else if (arg.key) {
    color = '#E99898';
  }
  else if (typeof(arg) === 'number') {
    var hueIsValid = hue && typeof(hue) === 'number' && hue > 0 && hue <= 360;

    if (!hueIsValid) {
      var colorCount = 30;
      var maxHue = 360;

      hue = (arg % colorCount) * (maxHue / colorCount);
    }

    color = `hsl(${hue}, ${60}%, ${75}%)`;
  }

  return color;
};

var libEvent = {
  keyPressed(event, key) {
    var keyCode = event.keyCode;
    var pressed = false;

    if (key === 'left') pressed = keyCode === 37;
    if (key === 'up') pressed = keyCode === 38;
    if (key === 'right') pressed = keyCode === 39;
    if (key === 'down') pressed = keyCode === 40;

    if (key === 'c') pressed = keyCode === 67;
    if (key === 's') pressed = keyCode === 83;
    if (key === 'v') pressed = keyCode === 86;
    if (key === 'x') pressed = keyCode === 88;
    if (key === 'y') pressed = keyCode === 89;
    if (key === 'z') pressed = keyCode === 90;
    if (key === '+') pressed = keyCode === 187;
    if (key === '-') pressed = keyCode === 189;

    if (key === 'enter') pressed = keyCode === 13;
    if (key === 'ctrlcmd') pressed = (event.ctrlKey || event.metaKey || event.which === 22 || event.which === 224);
    if (key === 'alt') pressed = (event.altKey);
    if (key === 'esc') pressed = keyCode === 27;
    if (key === 'tab') pressed = keyCode === 9;
    if (key === 'shift') pressed = event.shiftKey;
    if (key === 'backspace') pressed = keyCode === 8;
    if (key === 'space') pressed = keyCode === 32;
    if (key === 'delete') pressed = keyCode === 8 || keyCode === 46;

    return pressed;
  },

  numberKeyPressed(event) {
    var numberKeyCodes = [49, 50, 51, 52, 53, 54, 55, 56, 57, 48];
    var keyCode = event.keyCode;

    return _.includes(numberKeyCodes, keyCode);
  }
};

var time = {};

time.scales = [
  {abbreviation: 'm', matchStrings: ['mi'], title: 'minute', minutes: 1, maxMinutes: 59},
  {abbreviation: 'h', matchStrings: ['ho', 'hr'], title: 'hour', minutes: 60, maxMinutes: 479},
  {abbreviation: 'd', matchStrings: ['d'], title: 'day', minutes: 480, maxMinutes: 2399},
  {abbreviation: 'w', matchStrings: ['w'], title: 'week', minutes: 2400, maxMinutes: 10079},
  {abbreviation: 'mo', matchStrings: ['mo', 'mnth'], title: 'month', minutes: 10080, maxMinutes: 125279},
  {abbreviation: 'y', matchStrings: ['ye', 'yr'], title: 'year', minutes: 125280}
];

time.toLabel = (minutes, {format='short', precision=10}={}) => {
  minutes = Math.round(minutes) || 0; //HINT prevent invalid minutes values

  var scale = _$1.find(time.scales, scale => Math.abs(minutes) <= scale.maxMinutes) || _$1.last(time.scales);
  var number = Math.round(minutes / scale.minutes * 10) / 10;
  var suffix = format === 'short' ? scale.abbreviation : ` ${scale.title}s`;

  return (number === 0 || number === -1) ? '' : `${number}${suffix}`;
};

time.toMinutes = (label) => {
  label = label || '';

  var number = parseFloat(label) || 0;
  var unit = _$1.trim(label.replace(`${number}`, ''));

  var scale = _$1.find(time.scales, scale => {
    return unit === scale.abbreviation || _$1.some(scale.matchStrings, matchString => _$1.includes(unit, matchString));
  }) || time.scales[1]; //HINT default to hours if someone just types a number

  return number * scale.minutes;
};

var validation = {};

validation.emailIsValid = (email) => {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return re.test(String(email).toLowerCase());
};

var sf = {api, colors, date, event: libEvent, time, validation};

exports.api = api;
exports.colors = colors;
exports.date = date;
exports.default = sf;
exports.event = libEvent;
exports.time = time;
exports.validation = validation;
